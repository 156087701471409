import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';

import { IFangelse } from 'src/app/models/fangelse';
import { FangelseService } from 'src/app/services/fangelse.service';

@Component({
  selector: 'app-fangelse',
  templateUrl: './fangelse.component.html',
  styleUrls: ['../databaser.scss']
})
export class FangelseComponent implements OnInit {
  public fangelse: IFangelse[] = [];
  public page: Number = 1;
  public pageSize: Number = 10;
  public showInfoForNumber: Number = 0;

  get fornamnCtrl() { return this.fangelseForm.controls.fornamn as UntypedFormControl; }
  get efternamnCtrl() { return this.fangelseForm.controls.efternamn as UntypedFormControl; }

  fangelseForm: UntypedFormGroup = this.fb.group({
    fornamn: [''],
    efternamn: ['']
  });

  constructor(private fb: UntypedFormBuilder, private fangelseService: FangelseService, private config: NgbPaginationConfig) {
    config.size = 'sm';
    config.boundaryLinks = true;
  };

  ngOnInit(): void {
  }

  public onSubmit(): void {
    this.fangelseService.searchFangelse(
      this.fornamnCtrl.value,
      this.efternamnCtrl.value)
      .subscribe((res: IFangelse[]) => {
        this.fangelse = res;
        this.showInfoForNumber = 0;        
      });
  }

  public showInfo(index: number): boolean {
    return index === this.showInfoForNumber;
  }

  public switchInfo(index: number): void {
    if (this.showInfoForNumber === index) {
      this.showInfoForNumber = 0
    }
    else
    {
      this.showInfoForNumber = index;
    }
  }

  public resetSearchForm(): void {
    this.fangelse = [];
    this.fangelseForm.reset();
  }
}
