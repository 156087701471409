import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IMantal } from '../models/mantal';

@Injectable({
  providedIn: 'root'
})
export class MantalService {
  private endpoint: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.endpoint = new URL('taxCensus', baseUrl).href;
  }

  searchMantal(fornamn: string, efternamn: string, hemort: string) {
    let params = new HttpParams();
    params = params.append('fornamn', fornamn ?? '');
    params = params.append('efternamn', efternamn ?? '');
    params = params.append('hemort', hemort ?? '');
    return this.http.get<IMantal[]>(this.endpoint, { params });
  }
}

