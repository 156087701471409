<h1 id="tableLabel">Fängelseregister</h1>

<p>Här kan man söka efter personer från Ådals-Lidens församling som återfunnits i fängelsehandlingar. Namnen är skrivna källtroget vilket man måste tänka på när man söker i registret.</p>

<form class="mb-3" [formGroup]="fangelseForm" (ngSubmit)="onSubmit()">
  <fieldset class="border col-12 p-2">
    <legend class="w-auto">Sök fängelsehandlingar</legend>
    <div class="row mb-2">      
      <div class="col-4">
        <label class="col-form-label">Förnamn:</label>
        <input class="form-control" type="text" formControlName="fornamn">
      </div>
      <div class="col-4">
        <label class="col-form-label">Efternamn:</label>
        <input class="form-control" type="text" formControlName="efternamn">
      </div>
    </div>

    <button type="submit" class="btn btn-primary">Sök</button>
    <button type="button" (click)="resetSearchForm()" class="btn btn-outline-secondary ml-1">Rensa</button>
  </fieldset>
</form>

<div *ngIf="fangelse.length!==0">
  <div class="col-12">
    <div class="row font-weight-bold">
        <div class="col col-1">#</div>
        <div class="col col-2">Förnamn</div>
        <div class="col col-2">Efternamn</div>
        <div class="col col-2">Ankomstdatum</div>
        <div class="col col-2">Frigivningsdatum</div>
        <div class="col col-2">Fängelse</div>
        <div class="col col-1"></div>
    </div>
  </div>
  <div class="col-12" *ngFor="let post of fangelse; let i = index">
    <div class="row mt-2">
      <div class="col col-1">{{ i + 1 }}</div>
      <div class="col col-2">{{ post.fnamn }}</div>
      <div class="col col-2">{{ post.enamn }}</div>
      <div class="col col-2">{{ post.datumAnkomst }}</div>
      <div class="col col-2">{{ post.datumFrigivning }}</div>
      <div class="col col-2">{{ post.fangelseNamn }}</div>
      <div class="col col-1"><a [routerLink]="[]" (click)="switchInfo(i+1)">>></a></div>
    </div>
    <div class="mt-1" *ngIf="showInfo(i+1)">
      <div class="row">
        <div class="col col-1"></div>
        <div class="col col-2 info-text text-dark"><b>Namn: </b></div>
        <div class="col col-6 info-text text-dark">{{ post.fnamn }} {{ post.enamn }}</div>
      </div>
      <div class="row" *ngIf="post.datumAnkomst">
        <div class="col col-1"></div>
        <div class="col col-2 info-text text-dark"><b>Ankomstdatum: </b></div>
        <div class="col col-6 info-text text-dark">{{ post.datumAnkomst }}</div>
      </div>
      <div class="row" *ngIf="post.datumFrigivning">
        <div class="col col-1"></div>
        <div class="col col-2 info-text text-dark"><b>Frigivningsdatum: </b></div>
        <div class="col col-6 info-text text-dark">{{ post.datumFrigivning }}</div>
      </div>
      <div class="row" *ngIf="post.fangelseNamn">
        <div class="col col-1"></div>
        <div class="col col-2 info-text text-dark"><b>Fängelse: </b></div>
        <div class="col col-6 info-text text-dark">{{ post.fangelseNamn }}</div>
      </div>
      <div class="row" *ngIf="post.brott">
        <div class="col col-1"></div>
        <div class="col col-2 info-text text-dark"><b>Brott: </b></div>
        <div class="col col-6 info-text text-dark">{{ post.brott }}</div>
      </div>
      <div class="row" *ngIf="post.brottstext">
        <div class="col col-1"></div>
        <div class="col col-2 info-text text-dark"><b>Brottstext: </b></div>
        <div class="col col-6 info-text text-dark">{{ post.brottstext }}</div>
      </div>  
      <div class="row" *ngIf="post.strafftid">
        <div class="col col-1"></div>
        <div class="col col-2 info-text text-dark"><b>Strafftid: </b></div>
        <div class="col col-6 info-text text-dark">{{ post.strafftid }}</div>
      </div>
      <div class="row">
        <div class="col col-1"></div>
        <div class="col col-2 info-text text-dark"><b>BildId: </b></div>
        <div class="col col-6 info-text text-dark"><a target="_new" href="https://sok.riksarkivet.se/bildvisning/{{ post.bildId }}">{{ post.bildId }}</a></div>
      </div>    
    </div>
  </div>
</div>
