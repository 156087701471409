import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { FodelseComponent } from './databaser/fodelse/fodelse.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { VigselComponent } from './databaser/vigsel/vigsel.component';
import { DodaComponent } from './databaser/doda/doda.component';
import { FangelseComponent } from './databaser/fangelse/fangelse.component';
import { MantalComponent } from './databaser/mantal/mantal.component';
import { RakenskaperComponent } from './databaser/rakenskaper/rakenskaper.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    AppComponent,
    FodelseComponent,
    NavMenuComponent,
    DodaComponent,
    VigselComponent,
    FangelseComponent,
    MantalComponent,
    RakenskaperComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'vigsel', component: VigselComponent },
      { path: 'fodelse', component: FodelseComponent },
      { path: 'doda', component: DodaComponent },
      { path: 'fangelse', component: FangelseComponent },
      { path: 'mantal', component: MantalComponent },
      { path: 'rakenskaper', component: RakenskaperComponent }
    ],
    { relativeLinkResolution: 'legacy' }),
    ReactiveFormsModule,
    NgbModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
