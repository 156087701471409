import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable, Inject } from "@angular/core";

import { IDoda } from "../models/doda";

@Injectable({
  providedIn: 'root'
})

export class DodaService {
  private endpoint: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.endpoint = new URL('death', baseUrl).href;
  }

  searchDoda(fornamn: string, efternamn: string, artal: string, hemort: string, 
      anh1_Fnamn: string, anh1_Enamn: string) {
    let params = new HttpParams();
    params = params.append('fornamn', fornamn ?? '');
    params = params.append('efternamn', efternamn ?? '');
    params = params.append('artal', artal ?? '');
    params = params.append('hemort', hemort ?? '');
    params = params.append('anh1_Fnamn', anh1_Fnamn ?? '');
    params = params.append('anh1_Enamn', anh1_Enamn ?? '');
    return this.http.get<IDoda[]>(this.endpoint, { params });
  }
}
