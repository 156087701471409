import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { RakenskaperService } from '../../services/rakenskaper.service';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { IRakenskaper } from '../../models/rakenskaper';

@Component({
  selector: 'app-rakenskaper',
  templateUrl: './rakenskaper.component.html',
  styleUrls: ['./rakenskaper.component.scss']
})

export class RakenskaperComponent {
  public rakenskaper: IRakenskaper[] = [];
  public page: Number = 1;
  public pageSize: Number = 10;
  public showInfoForNumber: Number = 0;

  get fornamnCtrl() { return this.rakenskaperForm.controls.fornamn as UntypedFormControl; }
  get efternamnCtrl() { return this.rakenskaperForm.controls.efternamn as UntypedFormControl; }
  get artalCtrl() { return this.rakenskaperForm.controls.artal as UntypedFormControl; }
  get hemortCtrl() { return this.rakenskaperForm.controls.hemort as UntypedFormControl; }

  rakenskaperForm: UntypedFormGroup = this.fb.group({
    fornamn: [''],
    efternamn: [''],
    artal: [''],
    hemort: ['']
  });

  constructor(private fb: UntypedFormBuilder, private rakenskaperService: RakenskaperService, private config: NgbPaginationConfig) {
    config.size = 'sm';
    config.boundaryLinks = true;
  };

  public onSubmit(): void {
    this.rakenskaperService.searchRakenskaper(
      this.fornamnCtrl.value,
      this.efternamnCtrl.value,
      this.artalCtrl.value,
      this.hemortCtrl.value)
      .subscribe((res: IRakenskaper[]) => {
        this.rakenskaper = res;
        this.showInfoForNumber = 0;
      });
  }

  public showInfo(index: number): boolean {
    return index === this.showInfoForNumber;
  }

  public switchInfo(index: number): void {
    if (this.showInfoForNumber === index) {
      this.showInfoForNumber = 0
    }
    else
    {
      this.showInfoForNumber = index;
    }
  }

  public resetSearchForm(): void {
    this.rakenskaper = [];
    this.rakenskaperForm.reset();
  }
}
