import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable, Inject } from "@angular/core";
import { Observable } from "rxjs";

import { IFodelse } from "../models/fodelse";

@Injectable({
  providedIn: 'root'
})

export class FodelseService {
  private endpoint: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.endpoint = new URL('birth/', baseUrl).href;
  }

  searchFodelse(namn: string, artal: string, hemort: string, 
      farFnamn: string, farEnamn: string, morFnamn: string, morEnamn: string) {
    let url = new URL('getFodelse', this.endpoint).href;
    let params = new HttpParams();
    params = params.append('namn', namn ?? '');
    params = params.append('artal', artal ?? '');
    params = params.append('hemort', hemort ?? '');
    params = params.append('farFnamn', farFnamn ?? '');
    params = params.append('farEnamn', farEnamn ?? '');
    params = params.append('morFnamn', morFnamn ?? '');
    params = params.append('morEnamn', morEnamn ?? '');
    return this.http.get<IFodelse[]>(this.endpoint, { params });
  }

  searchHemort(searchText: string): Observable<string[]> {
    console.log(searchText);
    let params = new HttpParams();
    let url = new URL('getHemort', this.endpoint).href;
    params = params.append('text', searchText ?? '');
    return this.http.get<string[]>(url, { params });
  }
}
