<h1 id="tableLabel">Kyrkoräkenskaper - Ådals-Liden 1710-1740</h1>

<p>Här kan man söka efter personer som finns med i kyrkoräkenskaper i Ådals-Lidens församling.</p>

<form class="mb-3" [formGroup]="rakenskaperForm" (ngSubmit)="onSubmit()">
  <fieldset class="border col-12 p-2">
    <legend class="w-auto">Sök räkenskaper</legend>
    <div class="row mb-2">
      <div class="col-4">
        <label class="col-form-label">Förnamn:</label>
        <input class="form-control" type="text" formControlName="fornamn">
      </div>
      <div class="col-4">
        <label class="col-form-label">Efternamn:</label>
        <input class="form-control" type="text" formControlName="efternamn">
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-4">
        <label class="col-form-label">Årtal:</label>
        <input class="form-control" type="text" formControlName="artal">
      </div>
      <div class="col-4">
        <label class="col-form-label">Hemort:</label>
        <input class="form-control" type="text" formControlName="hemort">
      </div>
    </div>

    <button type="submit" class="btn btn-primary">Sök</button>
    <button type="button" (click)="resetSearchForm()" class="btn btn-outline-secondary ml-1">Rensa</button>
  </fieldset>
</form>

<div *ngIf="rakenskaper.length!==0">
  <div class="col-12">
    <div class="row font-weight-bold">
        <div class="col col-1">#</div>
        <div class="col col-3">Namn</div>
        <div class="col col-2">Hemort</div>
        <div class="col col-1">Årtal</div>
        <div class="col col-4">Text</div>
        <div class="col col-1"></div>
    </div>
  </div>
  <div class="col-12" *ngFor="let post of rakenskaper; let i = index">
    <div class="row mt-2">
      <div class="col col-1">{{ i + 1 }}</div>
      <div class="col col-3">{{ post.fornamn }} {{ post.efternamn }}</div>
      <div class="col col-2">{{ post.hemort }}</div>
      <div class="col col-1">{{ post.artal }}</div>
      <div class="col col-4">{{ post.text }}</div>
      <div class="col col-1"><a [routerLink]="[]" (click)="switchInfo(i+1)">>></a></div>
    </div>
    <div class="mt-1" *ngIf="showInfo(i+1)">
      <div class="row">
        <div class="col col-1"></div>
        <div class="col col-2 info-text text-dark"><b>Namn: </b></div>
        <div class="col col-6 info-text text-dark">{{ post.fornamn }} {{ post.efternamn }}</div>
      </div>    
      <div class="row" *ngIf="post.hemort">
        <div class="col col-1"></div>
        <div class="col col-2 info-text text-dark"><b>Hemort: </b></div>
        <div class="col col-6 info-text text-dark">{{ post.hemort }}</div>
      </div>
      <div class="row" *ngIf="post.artal">
        <div class="col col-1"></div>
        <div class="col col-2 info-text text-dark"><b>Artal: </b></div>
        <div class="col col-6 info-text text-dark">{{ post.artal }}</div>
      </div>
      <div class="row" *ngIf="post.text">
        <div class="col col-1"></div>
        <div class="col col-2 info-text text-dark"><b>Avskrift: </b></div>
        <div class="col col-6 info-text text-dark">{{ post.text }}</div>
      </div>
      <div class="row">
        <div class="col col-1"></div>
        <div class="col col-2 info-text text-dark"><b>BildId: </b></div>
        <div class="col col-6 info-text text-dark"><a target="_new" href="https://sok.riksarkivet.se/bildvisning/{{ post.bildId }}">{{ post.bildId }}</a></div>
      </div>
    </div>
  </div>
</div>
