import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { VigselComponent } from './databaser/vigsel/vigsel.component';
import { FodelseComponent } from './databaser/fodelse/fodelse.component';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'vigsel', component: VigselComponent },
      { path: 'fodelse', component: FodelseComponent },
    ],
    { relativeLinkResolution: 'legacy' }),
  ]
})
export class AppRoutingModule { }
