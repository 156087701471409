import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IFangelse } from '../models/fangelse';

@Injectable({
  providedIn: 'root'
})
export class FangelseService {
  private endpoint: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.endpoint = new URL('prison', baseUrl).href;
  }

  searchFangelse(fornamn: string, efternamn: string) {
    let params = new HttpParams();
    params = params.append('fornamn', fornamn ?? '');
    params = params.append('efternamn', efternamn ?? '');
    return this.http.get<IFangelse[]>(this.endpoint, { params });
  }
}

