import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, catchError, map  } from 'rxjs/operators';

import { FodelseService } from '../../services/fodelse.service';
import { IFodelse } from '../../models/fodelse';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';

const byar = ['Betåsen', 'Rå', 'Lidgatu', 'Forsnäs', 'Sundmo', 'California', 'Colorado',
'Connecticut', 'Delaware', 'District Of Columbia', 'Federated States Of Micronesia', 'Florida', 'Georgia',
'Guam', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine',
'Marshall Islands', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana',
'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota',
'Northern Mariana Islands', 'Ohio', 'Oklahoma', 'Oregon', 'Palau', 'Pennsylvania', 'Puerto Rico', 'Rhode Island',
'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virgin Islands', 'Virginia',
'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'];

@Component({
  selector: 'app-fodelse',
  templateUrl: './fodelse.component.html',
  styleUrls: ['./fodelse.component.scss']
})

export class FodelseComponent {
  public fodelse: IFodelse[] = [];
  public page: Number = 1;
  public pageSize: Number = 10;
  public showInfoForNumber: Number = 0;
  public noHits: Boolean = false;

  get namnCtrl() { return this.fodelseForm.controls.namn as UntypedFormControl; }
  get artalCtrl() { return this.fodelseForm.controls.artal as UntypedFormControl; }
  get hemortCtrl() { return this.fodelseForm.controls.hemort as UntypedFormControl; }
  get farFnamnCtrl() { return this.fodelseForm.controls.farFnamn as UntypedFormControl; }
  get farEnamnCtrl() { return this.fodelseForm.controls.farEnamn as UntypedFormControl; }
  get morFnamnCtrl() { return this.fodelseForm.controls.morFnamn as UntypedFormControl; }
  get morEnamnCtrl() { return this.fodelseForm.controls.morEnamn as UntypedFormControl; }

  fodelseForm: UntypedFormGroup = this.fb.group({
    namn: [''],
    artal: [''],
    hemort: [''],
    farFnamn: [''],
    farEnamn: [''],
    morFnamn: [''],
    morEnamn: ['']
  });

  constructor(private fb: UntypedFormBuilder, private fodelseService: FodelseService, private config: NgbPaginationConfig) {
    config.size = 'sm';
    config.boundaryLinks = true;
  };

  search = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap( (searchText) =>  this.fodelseService.searchHemort(searchText) )
    );
  }

  public onSubmit(): void {
    this.noHits = false;
    this.fodelseService.searchFodelse(
      this.namnCtrl.value,
      this.artalCtrl.value,
      this.hemortCtrl.value,
      this.farFnamnCtrl.value,
      this.farEnamnCtrl.value,
      this.morFnamnCtrl.value,
      this.morEnamnCtrl.value)
      .subscribe((res: IFodelse[]) => {
        this.fodelse = res;
        if (this.fodelse.length === 0) {
          this.noHits = true;
        }
        this.showInfoForNumber = 0;
      });
  }

  public showInfo(index: number): boolean {
    return index === this.showInfoForNumber;
  }

  public switchInfo(index: number): void {
    if (this.showInfoForNumber === index) {
      this.showInfoForNumber = 0
    }
    else
    {
      this.showInfoForNumber = index;
    }
  }

  public resetSearchForm(): void {
    this.fodelse = [];
    this.fodelseForm.reset();
  }

  public setEnterSign(post: IFodelse): boolean {
    return post.far_Fnamn || post.far_Enamn ? true : false;
  }
}
