import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { DodaService } from '../../services/doda.service';
import { IDoda } from '../../models/doda';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-doda',
  templateUrl: './doda.component.html',
  styleUrls: ['../databaser.scss']
})

export class DodaComponent {
  public doda: IDoda[] = [];
  public page: Number = 1;
  public pageSize: Number = 10;
  public showInfoForNumber: Number = 0;
  public noHits: Boolean = false;

  get fornamnCtrl() { return this.dodaForm.controls.fornamn as UntypedFormControl; }
  get efternamnCtrl() { return this.dodaForm.controls.efternamn as UntypedFormControl; }
  get artalCtrl() { return this.dodaForm.controls.artal as UntypedFormControl; }
  get hemortCtrl() { return this.dodaForm.controls.hemort as UntypedFormControl; }
  get anh1FnamnCtrl() { return this.dodaForm.controls.anh1_Fnamn as UntypedFormControl; }
  get anh1EnamnCtrl() { return this.dodaForm.controls.anh1_Enamn as UntypedFormControl; }

  dodaForm: UntypedFormGroup = this.fb.group({
    fornamn: [''],
    efternamn: [''],
    artal: [''],
    hemort: [''],
    anh1_Fnamn: [''],
    anh1_Enamn: ['']
  });

  constructor(private fb: UntypedFormBuilder, private dodaService: DodaService, private config: NgbPaginationConfig) {
    config.size = 'sm';
    config.boundaryLinks = true;
  };

  public onSubmit(): void {
    this.noHits = false;
    this.dodaService.searchDoda(
      this.fornamnCtrl.value,
      this.efternamnCtrl.value,
      this.artalCtrl.value,
      this.hemortCtrl.value,
      this.anh1FnamnCtrl.value,
      this.anh1EnamnCtrl.value)
      .subscribe((res: IDoda[]) => {
        this.doda = res;
        if (this.doda.length === 0) {
          this.noHits = true;
        }
        this.showInfoForNumber = 0;
      });
  }

  public showInfo(index: number): boolean {
    return index === this.showInfoForNumber;
  }

  public switchInfo(index: number): void {
    if (this.showInfoForNumber === index) {
      this.showInfoForNumber = 0
    }
    else
    {
      this.showInfoForNumber = index;
    }
  }

  public resetSearchForm(): void {
    this.doda = [];
    this.dodaForm.reset();
  }
}
