import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { IMantal } from '../../models/mantal';

import { MantalService } from '../../services/mantal.service';

@Component({
  selector: 'app-mantal',
  templateUrl: './mantal.component.html',
  styleUrls: ['../databaser.scss']
})
export class MantalComponent implements OnInit {
  public mantal: IMantal[] = [];
  public page: Number = 1;
  public pageSize: Number = 10;
  public showInfoForNumber: Number = 0;

  get fornamnCtrl() { return this.mantalForm.controls.fornamn as UntypedFormControl; }
  get efternamnCtrl() { return this.mantalForm.controls.efternamn as UntypedFormControl; }
  get hemortCtrl() { return this.mantalForm.controls.hemort as UntypedFormControl; }

  mantalForm: UntypedFormGroup = this.fb.group({
    fornamn: [''],
    efternamn: [''],
    hemort: ['']
  });

  constructor(private fb: UntypedFormBuilder, private mantalService: MantalService, private config: NgbPaginationConfig) {
    config.size = 'sm';
    config.boundaryLinks = true;
  };

  ngOnInit(): void {
  }

  public onSubmit(): void {
    this.showInfoForNumber = 0;
    this.mantalService.searchMantal(
      this.fornamnCtrl.value,
      this.efternamnCtrl.value,
      this.hemortCtrl.value)
      .subscribe((res: IMantal[]) => {
        this.mantal = res;
        this.showInfoForNumber = 0;        
      });
  }

  public showInfo(index: number): boolean {
    return index === this.showInfoForNumber;
  }

  public switchInfo(index: number): void {
    if (this.showInfoForNumber === index) {
      this.showInfoForNumber = 0
    }
    else
    {
      this.showInfoForNumber = index;
    }
  }

  public resetSearchForm(): void {
    this.mantal = [];
    this.mantalForm.reset();
  }
}
