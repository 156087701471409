<h1 id="tableLabel">Dödregister - Ådals-Liden 1830-1894</h1>

<p>Här kan man söka efter personer som finns med i dödboken i Ådals-Lidens församling. Namnen är skrivna källtroget vilket man måste tänka på när man söker i registret.</p>

<form class="mb-3" [formGroup]="dodaForm" (ngSubmit)="onSubmit()">
  <fieldset class="border col-12 p-2">
    <legend class="w-auto">Sök dödsnotis</legend>
    <div class="row mb-2">      
      <div class="col-4">
        <label class="col-form-label">Förnamn:</label>
        <input class="form-control" type="text" formControlName="fornamn">
      </div>
      <div class="col-4">
        <label class="col-form-label">Efternamn:</label>
        <input class="form-control" type="text" formControlName="efternamn">
      </div>
      <div class="col-4">
        <label class="col-form-label">Årtal:</label>
        <input class="form-control" type="text" formControlName="artal">
      </div>
    </div>
    <div class="row mb-2"> 
      <div class="col-4">
        <label class="col-form-label">Hemort:</label>
        <input class="form-control" type="text" formControlName="hemort">
      </div>     
      <div class="col-4">
        <label class="col-form-label">Anhörigs förnamn:</label>
        <input class="form-control" type="text" formControlName="anh1_Fnamn">
      </div>
      <div class="col-4">
        <label class="col-form-label">Anhörigs efternamn:</label>
        <input class="form-control" type="text" formControlName="anh1_Enamn">
      </div>
    </div>

    <button type="submit" class="btn btn-primary">Sök</button>
    <button type="button" (click)="resetSearchForm()" class="btn btn-outline-secondary ml-1">Rensa</button>
  </fieldset>
</form>

<div *ngIf="noHits">
  <div class="col-6 border">
    <b>Inga poster hittades på denna sökning!</b><br/><br/>

    <u><i>Tips</i></u>
    <li>Sök inte med för många parametrar.</li>
    <li>Uppgifterna är avskrivna källtroget från dödboken.</li>
    <li>Innehåller bara åren 1830-1894 för Ådals-Lidens församling</li>
  </div>
</div>

<div *ngIf="doda.length!==0">
  <div class="col-12">
    <div class="row font-weight-bold">
        <div class="col col-1">#</div>
        <div class="col col-2">Förnamn</div>
        <div class="col col-2">Efternamn</div>
        <div class="col col-2">Dödsdatum</div>
        <div class="col col-2">Hemort</div>
        <div class="col col-2">Anhörig</div>
        <div class="col col-1"></div>
    </div>
  </div>
  <div class="col-12" *ngFor="let post of doda; let i = index">
    <div class="row mt-2">
      <div class="col col-1">{{ i + 1 }}</div>
      <div class="col col-2">{{ post.fnamn }}</div>
      <div class="col col-2">{{ post.enamn }}</div>
      <div class="col col-2">{{ !post.dodsdatum && post.begravningsdatum ? post.begravningsdatum + 'beg' : post.dodsdatum }}</div>
      <div class="col col-2">{{ post.hemort }}</div>
      <div class="col col-2">{{ post.anh1_Fnamn }} {{ post.anh1_Enamn }}</div>
      <div class="col col-1"><a [routerLink]="[]" (click)="switchInfo(i+1)">>></a></div>
    </div>
    <div class="mt-1" *ngIf="showInfo(i+1)">
      <div class="row">
        <div class="col col-1"></div>
        <div class="col col-2 info-text text-dark"><b>Namn: </b></div>
        <div class="col col-6 info-text text-dark">{{ post.fnamn }} {{ post.enamn }}</div>
      </div>
      <div class="row" *ngIf="post.dodsdatum">
        <div class="col col-1"></div>
        <div class="col col-2 info-text text-dark"><b>Dödsdatum: </b></div>
        <div class="col col-6 info-text text-dark">{{ post.dodsdatum }}</div>
      </div>
      <div class="row" *ngIf="post.begravningsdatum">
        <div class="col col-1"></div>
        <div class="col col-2 info-text text-dark"><b>Begravningsdatum: </b></div>
        <div class="col col-6 info-text text-dark">{{ post.begravningsdatum }}</div>
      </div>
      <div class="row" *ngIf="post.anh1_Fnamn || post.anh1_Enamn">
        <div class="col col-1"></div>
        <div class="col col-2 info-text text-dark"><b>Anhörigs namn: </b></div>
        <div class="col col-6 info-text text-dark">{{ post.anh1_Fnamn }} {{ post.anh1_Enamn }}</div>
      </div>
      <div class="row" *ngIf="post.anm">
        <div class="col col-1"></div>
        <div class="col col-2 info-text text-dark"><b>Anmärkning: </b></div>
        <div class="col col-6 info-text text-dark">{{ post.anm }}</div>
      </div>    
      <div class="row">
        <div class="col col-1"></div>
        <div class="col col-2 info-text text-dark"><b>Volym: </b></div>
        <div class="col col-6 info-text text-dark">{{ post.volym }}, s. {{ post.sida }}</div>
      </div>
      <div class="row">
        <div class="col col-1"></div>
        <div class="col col-2 info-text text-dark"><b>BildId: </b></div>
        <div class="col col-6 info-text text-dark"><a target="_new" href="https://sok.riksarkivet.se/bildvisning/{{ post.bildId }}">{{ post.bildId }}</a></div>
      </div>    
    </div>
  </div>
</div>
