<h1 id="tableLabel">Födelseregister - Ådals-Liden 1751-1900</h1>

<p>Här kan man söka efter personer som finns med i födelseboken i Ådals-Lidens församling. Namnen är skrivna källtroget vilket man måste tänka på när man söker i registret.</p>

<form class="mb-3" [formGroup]="fodelseForm" (ngSubmit)="onSubmit()">
  <fieldset class="border col-12 p-2">
    <legend class="w-auto">Sök födelse</legend>
    <div class="row mb-2">      
      <div class="col-4">
        <label class="col-form-label">Förnamn:</label>
        <input class="form-control" type="text" formControlName="namn">
      </div>
      <div class="col-4">
        <label class="col-form-label">Födelseår:</label>
        <input class="form-control" type="text" formControlName="artal">
      </div>
      <div class="col-4">
        <label class="col-form-label">Hemort:</label>
        <input class="form-control" type="text" formControlName="hemort">
      </div>
    </div>
    <div class="row mb-2">      
      <div class="col-4">
        <label class="col-form-label">Fars förnamn:</label>
        <input class="form-control" type="text" formControlName="farFnamn">
      </div>
      <div class="col-4">
        <label class="col-form-label">Fars efternamn:</label>
        <input class="form-control" type="text" formControlName="farEnamn">
      </div>
    </div>
    <div class="row mb-2">      
      <div class="col-4">
        <label class="col-form-label">Mors förnamn:</label>
        <input class="form-control" type="text" formControlName="morFnamn">
      </div>
      <div class="col-4">
        <label class="col-form-label">Mors efternamn:</label>
        <input class="form-control" type="text" formControlName="morEnamn">
      </div>
    </div>

    <button type="submit" class="btn btn-primary">Sök</button>
    <button type="button" (click)="resetSearchForm()" class="btn btn-outline-secondary ml-1">Rensa</button>
  </fieldset>
</form>

<div *ngIf="noHits">
  <div class="col-6 border">
    <b>Inga poster hittades på denna sökning!</b><br/><br/>

    <u><i>Tips</i></u>
    <li>Sök inte med för många parametrar.</li>
    <li>Uppgifterna är avskrivna källtroget från födelseboken.</li>
    <li>Innehåller bara åren 1751-1900 för Ådals-Lidens församling</li>
  </div>
</div>

<div *ngIf="fodelse.length!==0">
  <div class="col-12">
    <div class="row font-weight-bold">
        <div class="col col-1">#</div>
        <div class="col col-2">Namn</div>
        <div class="col col-2">Födelsedatum</div>
        <div class="col col-2">Födelseort</div>
        <div class="col col-23">Föräldrar</div>
        <div class="col col-1"></div>
    </div>
  </div>
  <div class="col-12" *ngFor="let post of fodelse; let i = index">
    <div class="row mt-2">
      <div class="col col-1">{{ i + 1 }}</div>
      <div class="col col-2">{{ post.namn }}</div>
      <div class="col col-2">{{ !post.datum && post.dopdatum ? post.dopdatum + 'dp' : post.datum }}</div>
      <div class="col col-2">{{ post.mor_Hemort ? post.mor_Hemort : post.far_Hemort }}</div>
      <div class="col col-3">{{ post.far_Fnamn }} {{ post.far_Enamn }}<br *ngIf="setEnterSign(post)">{{ post.mor_Fnamn }} {{ post.mor_Enamn }}</div>
      <div class="col col-1"><a [routerLink]="[]" (click)="switchInfo(i+1)">>></a></div>
    </div>
    <div class="mt-1" *ngIf="showInfo(i+1)">
      <div class="row">
        <div class="col col-1"></div>
        <div class="col col-2 info-text text-dark"><b>Namn: </b></div>
        <div class="col col-6 info-text text-dark">{{ post.namn }}</div>
      </div>
      <div class="row" *ngIf="post.datum">
        <div class="col col-1"></div>
        <div class="col col-2 info-text text-dark"><b>Födelsedatum: </b></div>
        <div class="col col-6 info-text text-dark">{{ post.datum }}{{ post.arsnr ? ' (årsnr: ' + post.arsnr + ')' : '' }}</div>
      </div>
      <div class="row" *ngIf="post.dopdatum">
        <div class="col col-1"></div>
        <div class="col col-2 info-text text-dark"><b>Dopdatum: </b></div>
        <div class="col col-6 info-text text-dark">{{ post.dopdatum }}</div>
      </div>
      <div class="row" *ngIf="post.far_Fnamn || post.far_Enamn">
        <div class="col col-1"></div>
        <div class="col col-2 info-text text-dark"><b>Fars namn: </b></div>
        <div class="col col-6 info-text text-dark">{{ post.far_Fnamn }} {{ post.far_Enamn }}</div>
      </div>
      <div class="row" *ngIf="post.far_Titel">
        <div class="col col-1"></div>
        <div class="col col-2 info-text text-dark"><b>Fars yrke/titel: </b></div>
        <div class="col col-6 info-text text-dark">{{ post.far_Titel }}</div>
      </div>
      <div class="row" *ngIf="post.far_Hemort">
        <div class="col col-1"></div>
        <div class="col col-2 info-text text-dark"><b>Fars hemort: </b></div>
        <div class="col col-6 info-text text-dark">{{ post.far_Hemort }}{{ post.far_Hfl_Sida ? ' (s. ' + post.far_Hfl_Sida + ')' : '' }}</div>
      </div>
      <div class="row" *ngIf="post.far_Fodd">
        <div class="col col-1"></div>
        <div class="col col-2 info-text text-dark"><b>Fars födelse/ålder: </b></div>
        <div class="col col-6 info-text text-dark">{{ post.far_Fodd }}</div>
      </div>      
      <div class="row" *ngIf="post.mor_Fnamn || post.mor_Enamn">
        <div class="col col-1"></div>
        <div class="col col-2 info-text text-dark"><b>Mors namn: </b></div>
        <div class="col col-6 info-text text-dark">{{ post.mor_Fnamn }} {{ post.mor_Enamn }}</div>
      </div>
      <div class="row" *ngIf="post.mor_Titel">
        <div class="col col-1"></div>
        <div class="col col-2 info-text text-dark"><b>Mors yrke/titel: </b></div>
        <div class="col col-6 info-text text-dark">{{ post.mor_Titel }}</div>
      </div>
      <div class="row" *ngIf="post.mor_Hemort">
        <div class="col col-1"></div>
        <div class="col col-2 info-text text-dark"><b>Mors hemort: </b></div>
        <div class="col col-6 info-text text-dark">{{ post.mor_Hemort }}{{ post.mor_Hfl_Sida ? ' (s. ' + post.mor_Hfl_Sida + ')' : '' }}</div>
      </div>
      <div class="row" *ngIf="post.mor_Fodd">
        <div class="col col-1"></div>
        <div class="col col-2 info-text text-dark"><b>Mors födelse/ålder: </b></div>
        <div class="col col-6 info-text text-dark">{{ post.mor_Fodd }}</div>
      </div>
      <div class="row" *ngIf="post.dopvittnen">
        <div class="col col-1"></div>
        <div class="col col-2 info-text text-dark"><b>Dopvittnen: </b></div>
        <div class="col col-6 info-text text-dark">{{ post.dopvittnen }}</div>
      </div>
      <div class="row" *ngIf="post.anm">
        <div class="col col-1"></div>
        <div class="col col-2 info-text text-dark"><b>Anmärkning: </b></div>
        <div class="col col-6 info-text text-dark">{{ post.anm }}</div>
      </div>
      <div class="row">
        <div class="col col-1"></div>
        <div class="col col-2 info-text text-dark"><b>Volym: </b></div>
        <div class="col col-6 info-text text-dark">{{ post.volym }}, s. {{ post.sida }}</div>
      </div>
      <div class="row">
        <div class="col col-1"></div>
        <div class="col col-2 info-text text-dark"><b>BildId - födelsebok: </b></div>
        <div class="col col-6 info-text text-dark"><a target="_new" href="https://sok.riksarkivet.se/bildvisning/{{ post.bildId }}">{{ post.bildId }}</a></div>
      </div>
      <div class="row" *ngIf="post.bildId_hfl">
        <div class="col col-1"></div>
        <div class="col col-2 info-text text-dark"><b>BildId - husförhör: </b></div>
        <div class="col col-6 info-text text-dark"><a target="_new" href="https://sok.riksarkivet.se/bildvisning/{{ post.bildId_hfl }}">{{ post.bildId_hfl }}</a></div>
      </div>
    </div>
  </div>
</div>
