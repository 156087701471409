import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable, Inject } from "@angular/core";

import { IRakenskaper } from "../models/rakenskaper";

@Injectable({
  providedIn: 'root'
})

export class RakenskaperService {
  private endpoint: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.endpoint = new URL('accounts', baseUrl).href;
  }

  searchRakenskaper(fornamn: string, efternamn: string, artal: string, hemort: string) {
    let params = new HttpParams();
    params = params.append('fornamn', fornamn ?? '');
    params = params.append('efternamn', efternamn ?? '');
    params = params.append('artal', artal ?? '');
    params = params.append('hemort', hemort ?? '');
    return this.http.get<IRakenskaper[]>(this.endpoint, { params });
  }
}
