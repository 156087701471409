<header>
  <nav class="navbar navbar-expand-sm navbar-toggleable-sm navbar-light bg-white border-bottom box-shadow mb-3">
    <div class="container">
      <a class="navbar-brand" [routerLink]="['/']">adals-liden.net</a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target=".navbar-collapse"
        aria-label="Toggle navigation"
        [attr.aria-expanded]="isExpanded"
        (click)="toggle()"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="navbar-collapse collapse d-sm-inline-flex flex-sm-row-reverse"
        [ngClass]="{ show: isExpanded }"
      >
        <ul class="navbar-nav flex-grow">
          <li class="nav-item"
              [routerLinkActive]="['link-active']"
              [routerLinkActiveOptions]="{ exact: true }">
            <a class="nav-link text-dark" [routerLink]="['/']">Hem</a>
          </li>
          <li class="nav-item" [routerLinkActive]="['link-active']">
          </li>
          <li class="nav-item dropdown ml-auto">
            <a class="nav-link dropdown-toggle" id="navbarDropdown" data-toggle="dropdown" (click)="show=!show">Databaser</a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown" [ngClass]="{show: show === true}">
              <a class="dropdown-item" [routerLink]="['/fodelse']" (click)="show=!show">Födelseregister</a>
              <!--<a class="dropdown-item" [routerLink]="['/vigsel']" (click)="show=!show">Vigselregister</a>-->
              <a class="dropdown-item" [routerLink]="['/doda']" (click)="show=!show">Dödregister</a>
              <a class="dropdown-item" [routerLink]="['/rakenskaper']" (click)="show=!show">Kyrkoräkenskaper</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" [routerLink]="['/fangelse']" (click)="show=!show">Fängelseregister</a>
              <a class="dropdown-item" [routerLink]="['/mantal']" (click)="show=!show">Mantalslängder</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>
