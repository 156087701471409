<h1 id="tableLabel">Mantalslängder från Ådals-Liden 1741-1745</h1>

<p>Här kan man söka efter personer från Ådals-Lidens församling som återfunnits i mantalslängderna. Namnen är skrivna källtroget vilket man måste tänka på när man söker i registret.</p>

<form class="mb-3" [formGroup]="mantalForm" (ngSubmit)="onSubmit()">
  <fieldset class="border col-12 p-2">
    <legend class="w-auto">Sök mantalslängder</legend>
    <div class="form-group row">    
      <div class="col-4">
        <label class="col-form-label">Förnamn:</label>
        <input class="form-control" type="text" formControlName="fornamn">
      </div>
      <div class="col-4">
        <label class="col-form-label">Efternamn:</label>
        <input class="form-control" type="text" formControlName="efternamn">
      </div>
      <div class="col-4">
        <label class="col-form-label">Hemort:</label>
        <input class="form-control" type="text" formControlName="hemort">
      </div>      
    </div>

    <button type="submit" class="btn btn-primary">Sök</button>
    <button type="button" (click)="resetSearchForm()" class="btn btn-outline-secondary ml-1">Rensa</button>
  </fieldset>
</form>

<div *ngIf="mantal.length!==0">
  <div class="col-12">
    <div class="row font-weight-bold">
        <div class="col col-1">#</div>
        <div class="col col-2">Förnamn</div>
        <div class="col col-2">Efternamn</div>
        <div class="col col-2">By</div>
        <div class="col col-2">Årtal</div>
        <div class="col col-2">Anmärkning</div>
        <div class="col col-1"></div>
    </div>
  </div>
  <div class="col-12" *ngFor="let post of mantal; let i = index">
    <div class="row mt-2">
      <div class="col col-1">{{ i + 1 }}</div>
      <div class="col col-2">{{ post.fnamn }}</div>
      <div class="col col-2">{{ post.enamn }}</div>
      <div class="col col-2">{{ post.bynamn }}{{ post.nr ? ', nr ' + post.nr : '' }}</div>
      <div class="col col-2">{{ post.artal }}</div>
      <div class="col col-2">{{ post.anm }}</div>
      <div class="col col-1"><a [routerLink]="[]" (click)="switchInfo(i+1)">>></a></div>
    </div>
    <div class="mt-1" *ngIf="showInfo(i+1)">
      <div class="row">
        <div class="col col-1"></div>
        <div class="col col-2 info-text text-dark"><b>Namn: </b></div>
        <div class="col col-6 info-text text-dark">{{ post.fnamn }} {{ post.enamn }}</div>
      </div>
      <div class="row" *ngIf="post.bynamn">
        <div class="col col-1"></div>
        <div class="col col-2 info-text text-dark"><b>Bynamn: </b></div>
        <div class="col col-6 info-text text-dark">{{ post.bynamn }}{{ post.nr ? ', nr ' + post.nr : '' }}</div>
      </div>
      <div class="row" *ngIf="post.artal">
        <div class="col col-1"></div>
        <div class="col col-2 info-text text-dark"><b>Årtal: </b></div>
        <div class="col col-6 info-text text-dark">{{ post.artal }}</div>
      </div>
      <div class="row" *ngFor="let siffra of post.siffror">
        <div class="col col-1"></div>
        <div class="col col-2 info-text text-dark"><b></b></div>
        <div class="col col-6 info-text text-dark">{{ siffra.siffra }} ({{ siffra.rubrik }})</div>
      </div>
      <div class="row">
        <div class="col col-1"></div>
        <div class="col col-2 info-text text-dark"><b>Länk till källa: </b></div>
        <div class="col col-6 info-text text-dark"><a target="_new" href="https://sok.riksarkivet.se/bildvisning/{{ post.bildId }}">{{ post.bildId }}</a></div>
      </div>    
    </div>
  </div>
</div>
